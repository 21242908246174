<template>
    <div>
    <div class="introPage" v-if="rulePage">
        <svg class="mt-5 ml-5" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="198.026" height="54.54" viewBox="0 0 198.026 54.54">
            <defs>
                <clipPath id="clip-path">
                <path id="Path_86" data-name="Path 86" d="M0,2.385H198.026v-54.54H0Z" transform="translate(0 52.155)" fill="#fff"/>
                </clipPath>
            </defs>
            <g id="Group_1913" data-name="Group 1913" transform="translate(-14 -14)">
                <g id="Group_9" data-name="Group 9" transform="translate(14 14)">
                <path id="Path_82" data-name="Path 82" d="M1.036,0,.979.032l0,0V0L.947.015.922,0V.03l-7.8,4.514V31.784L.954,36.3V45.5L-14.74,36.455v-.2l-.01-.006V9.1l-7.913,4.563v8.989l.052-.029v9.3l-.052-.03v8.989L.954,54.492v.048l.041-.024.042.024L16.6,45.571l.082-.047,7.9-4.552V13.634L1.036.066ZM8.8,22.786l.02-.011v-.006L.975,18.243V9.026l0,0,15.771,9.09v18.38l-.072.041L8.825,41.059l-.02.013Z" transform="translate(22.663)" fill="#fff"/>
                </g>
                <path id="Path_83" data-name="Path 83" d="M141.856-33.614h5.685v21.62h-5.685Z" transform="translate(20.487 67.003)" fill="#fff"/>
                <g id="Group_10" data-name="Group 10" transform="translate(162.342 24.439)">
                <path id="Path_84" data-name="Path 84" d="M0,.143v2.5H5.68V-3.13Z" transform="translate(0 3.13)" fill="#fff"/>
                </g>
                <g id="Group_12" data-name="Group 12" transform="translate(14 14)" clip-path="url(#clip-path)">
                <g id="Group_11" data-name="Group 11" transform="translate(58.819 10.439)">
                    <path id="Path_85" data-name="Path 85" d="M.448,1.357A6.85,6.85,0,0,1-4.126-.33V.9h-5.68V-26.389l5.68-3.278v10.31A6.658,6.658,0,0,1,.448-21.17c4.611,0,8.4,3.459,8.4,11.529,0,6.465-3.046,11-8.4,11M-.626-15.812A3.979,3.979,0,0,0-4.126-14v8.161A4.534,4.534,0,0,0-.3-3.984c2.181,0,3.378-1.895,3.378-5.521,0-4.457-1.483-6.307-3.708-6.307" transform="translate(9.806 29.667)" fill="#fff"/>
                </g>
                </g>
                <g id="Group_13" data-name="Group 13" transform="translate(106.072 24.548)">
                <path id="Path_87" data-name="Path 87" d="M0,1.337V-25.956l5.68-3.281V1.337Z" transform="translate(0 29.237)" fill="#fff"/>
                </g>
                <path id="Path_88" data-name="Path 88" d="M77.992-33.619h5.685V-12H77.992Z" transform="translate(17.566 67.003)" fill="#fff"/>
                <g id="Group_14" data-name="Group 14" transform="translate(95.559 24.431)">
                <path id="Path_89" data-name="Path 89" d="M0,.143v2.5H5.685v-5.78Z" transform="translate(0 3.134)" fill="#fff"/>
                </g>
                <g id="Group_19" data-name="Group 19" transform="translate(14 14)" clip-path="url(#clip-path)">
                <g id="Group_15" data-name="Group 15" transform="translate(101.702 10.435)">
                    <path id="Path_90" data-name="Path 90" d="M.567,1.337V-.024A6.658,6.658,0,0,1-4,1.791c-4.611,0-8.4-3.459-8.4-11.529,0-6.465,3.046-10.994,8.4-10.994a6.84,6.84,0,0,1,4.57,1.668v-6.891l5.686-3.281V1.337Zm0-14.866a4.533,4.533,0,0,0-3.83-1.849c-2.181,0-3.378,1.891-3.378,5.517,0,4.447,1.482,6.3,3.708,6.3a3.983,3.983,0,0,0,3.5-1.813Z" transform="translate(12.403 29.237)" fill="#fff"/>
                </g>
                <g id="Group_16" data-name="Group 16" transform="translate(125.026 10.435)">
                    <path id="Path_91" data-name="Path 91" d="M.571,1.337V-10.355c0-3.871-1.319-5.023-3.667-5.023s-3.7,1.151-3.7,4.9V1.337h-5.686V-25.956L-6.8-29.237v10.4a7.066,7.066,0,0,1,5.063-1.895c5.518,0,7.988,3.786,7.988,9.883V1.337Z" transform="translate(12.486 29.237)" fill="#fff"/>
                </g>
                <g id="Group_17" data-name="Group 17" transform="translate(157.001 19.393)">
                    <path id="Path_92" data-name="Path 92" d="M.6.945H-5.253l-7.779-21.616h6.279L-3.58-10.665c.535,1.646,1.111,3.7,1.36,4.733C-1.974-7-1.4-8.979-.86-10.665L2.314-20.671H8.407Z" transform="translate(13.032 20.671)" fill="#fff"/>
                </g>
                <g id="Group_18" data-name="Group 18" transform="translate(179.288 18.763)">
                    <path id="Path_93" data-name="Path 93" d="M.816.572h-12.93a4.162,4.162,0,0,0,4.447,4.08A4.834,4.834,0,0,0-3.958,3.129L.076,6.507a10.084,10.084,0,0,1-7.744,3.5c-6.215,0-10.173-4.407-10.173-11.244,0-6.628,4.162-11.279,9.638-11.279,5.766,0,9.1,5.105,9.1,11.279A18.011,18.011,0,0,1,.816.572M-8.491-7.4c-2.059,0-3.333,1.686-3.541,3.871H-4.62C-4.783-5.462-5.934-7.4-8.491-7.4" transform="translate(17.84 12.516)" fill="#fff"/>
                </g>
                </g>
            </g>
        </svg>
        <div class="ml-5" style="float:left; width:50%; position:absolute; top:30%">
            <h1 style="color:white; font-size:80px">Setting up your general model / elevation architectural rules</h1>
        </div>

        <a-button @click="showIntro" size="large" type='primary' style="background-color:#9693E7; border-color:#9693E7; position:absolute; bottom:30px; left:30px"><a-icon type='arrow-left' />BACK</a-button>

        <div class="headline" >
            <a-form style="margin-top:50px; margin-left:70px; margin-right:70px">
                <div class="mt-3">
                    <h2>How many of the same home model elevations can be sited per a block of 10?</h2>
                    <a-select v-model="control.elevPer10" style="width: 100%">
                        <a-select-option v-for="i in 5" :value="i" :key="`modelsidebyside${i}`">
                        {{i === 1? 'Only one' : `${i} out of 10`}}
                        </a-select-option>
                    </a-select>
                </div>
                <div class="mt-5">
                    <h2>What is the maximum percentage of occurrence of the same home model elevation in a streetscape?</h2>
                    <a-select v-model="control.elevStreetPercentage" style="width: 100%">
                        <a-select-option v-for="i in 5" :value="i" :key="`modelsidebyside${i}`">
                        {{i * 10}} %
                        </a-select-option>
                    </a-select>
                </div>
                <div class="mt-5">
                    <h2>How many times can the same home model elevation reoccur side by side?</h2>
                    <a-select placeholder="select" v-model="control.elevSide" style="width: 100%">
                        <a-select-option v-for="i in 5" :value="i" :key="`modelsidebyside${i}`">
                            {{i === 1? 'None' : `${i} in a row`}}
                        </a-select-option>
                    </a-select>
                </div>
                <div class="mt-5">
                    <h2>How many lots need to be between identical elevations?</h2>
                    <a-select v-model="control.sameElevLotSpaces" style="width: 100%">
                        <a-select-option v-for="i in 10" :value="i" :key="`modelsidebyside${i}`">
                        {{i === 1? '1 Lot Space' : `${i} Lot Spaces`}}
                        </a-select-option>
                    </a-select>
                </div>
                <div class="mt-5">
                    <h2>Can the identical elevations be across from each other?</h2>
                    <a-select v-model="control.sameElevAcross" style="width: 100%">
                        <a-select-option :value="1" >Yes</a-select-option>
                        <a-select-option :value="0" >No</a-select-option>
                    </a-select>
                </div>
                <div class="mt-5">
                    <h2>Identify the number of lot spaces required between identical architectural colour packages.</h2>
                    <a-select v-model="control.sameBrickLotSpaces" style="width: 100%">
                        <a-select-option v-for="i in 10" :value="i" :key="`modelsidebyside${i}`">
                        {{i === 1? '1 Lot Space' : `${i} Lot Spaces`}}
                        </a-select-option>
                    </a-select>
                </div>
                <div class="mt-5">
                    <h2>Can identical architectural color packages be across from each other?</h2>
                    <a-select v-model="control.sameBrickAcross" style="width: 100%">
                        <a-select-option :value="-1" >Does not apply</a-select-option>
                        <a-select-option :value="1" >Yes</a-select-option>
                        <a-select-option :value="0" >No</a-select-option>
                    </a-select>
                </div>
            </a-form>
            <a-button @click="maybeLater" size="large" style="position:absolute; bottom:30px; right:200px; border-color:white">MAYBE LATER</a-button>
            <a-button @click="applyRule" :disabled="control.elevSide == 'Choose an answer below:' || control.sameElevLotSpaces == 'Choose an answer below:' || control.elevPer10 == 'Choose an answer below:' || control.elevStreetPercentage == 'Choose an answer below:' || control.sameElevAcross == 'Choose an answer below:' || control.sameBrickLotSpaces == 'Choose an answer below:' || control.sameBrickAcross == 'Choose an answer below:'" size="large" type='primary' style="background-color:#9693E7; border-color:#9693E7; position:absolute; bottom:30px; right:30px">NEXT</a-button>
        </div>
    </div>
    <div class="introPage" v-if="imagePage">
        <svg class="mt-5 ml-5" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="198.026" height="54.54" viewBox="0 0 198.026 54.54">
            <defs>
                <clipPath id="clip-path">
                <path id="Path_86" data-name="Path 86" d="M0,2.385H198.026v-54.54H0Z" transform="translate(0 52.155)" fill="#fff"/>
                </clipPath>
            </defs>
            <g id="Group_1913" data-name="Group 1913" transform="translate(-14 -14)">
                <g id="Group_9" data-name="Group 9" transform="translate(14 14)">
                <path id="Path_82" data-name="Path 82" d="M1.036,0,.979.032l0,0V0L.947.015.922,0V.03l-7.8,4.514V31.784L.954,36.3V45.5L-14.74,36.455v-.2l-.01-.006V9.1l-7.913,4.563v8.989l.052-.029v9.3l-.052-.03v8.989L.954,54.492v.048l.041-.024.042.024L16.6,45.571l.082-.047,7.9-4.552V13.634L1.036.066ZM8.8,22.786l.02-.011v-.006L.975,18.243V9.026l0,0,15.771,9.09v18.38l-.072.041L8.825,41.059l-.02.013Z" transform="translate(22.663)" fill="#fff"/>
                </g>
                <path id="Path_83" data-name="Path 83" d="M141.856-33.614h5.685v21.62h-5.685Z" transform="translate(20.487 67.003)" fill="#fff"/>
                <g id="Group_10" data-name="Group 10" transform="translate(162.342 24.439)">
                <path id="Path_84" data-name="Path 84" d="M0,.143v2.5H5.68V-3.13Z" transform="translate(0 3.13)" fill="#fff"/>
                </g>
                <g id="Group_12" data-name="Group 12" transform="translate(14 14)" clip-path="url(#clip-path)">
                <g id="Group_11" data-name="Group 11" transform="translate(58.819 10.439)">
                    <path id="Path_85" data-name="Path 85" d="M.448,1.357A6.85,6.85,0,0,1-4.126-.33V.9h-5.68V-26.389l5.68-3.278v10.31A6.658,6.658,0,0,1,.448-21.17c4.611,0,8.4,3.459,8.4,11.529,0,6.465-3.046,11-8.4,11M-.626-15.812A3.979,3.979,0,0,0-4.126-14v8.161A4.534,4.534,0,0,0-.3-3.984c2.181,0,3.378-1.895,3.378-5.521,0-4.457-1.483-6.307-3.708-6.307" transform="translate(9.806 29.667)" fill="#fff"/>
                </g>
                </g>
                <g id="Group_13" data-name="Group 13" transform="translate(106.072 24.548)">
                <path id="Path_87" data-name="Path 87" d="M0,1.337V-25.956l5.68-3.281V1.337Z" transform="translate(0 29.237)" fill="#fff"/>
                </g>
                <path id="Path_88" data-name="Path 88" d="M77.992-33.619h5.685V-12H77.992Z" transform="translate(17.566 67.003)" fill="#fff"/>
                <g id="Group_14" data-name="Group 14" transform="translate(95.559 24.431)">
                <path id="Path_89" data-name="Path 89" d="M0,.143v2.5H5.685v-5.78Z" transform="translate(0 3.134)" fill="#fff"/>
                </g>
                <g id="Group_19" data-name="Group 19" transform="translate(14 14)" clip-path="url(#clip-path)">
                <g id="Group_15" data-name="Group 15" transform="translate(101.702 10.435)">
                    <path id="Path_90" data-name="Path 90" d="M.567,1.337V-.024A6.658,6.658,0,0,1-4,1.791c-4.611,0-8.4-3.459-8.4-11.529,0-6.465,3.046-10.994,8.4-10.994a6.84,6.84,0,0,1,4.57,1.668v-6.891l5.686-3.281V1.337Zm0-14.866a4.533,4.533,0,0,0-3.83-1.849c-2.181,0-3.378,1.891-3.378,5.517,0,4.447,1.482,6.3,3.708,6.3a3.983,3.983,0,0,0,3.5-1.813Z" transform="translate(12.403 29.237)" fill="#fff"/>
                </g>
                <g id="Group_16" data-name="Group 16" transform="translate(125.026 10.435)">
                    <path id="Path_91" data-name="Path 91" d="M.571,1.337V-10.355c0-3.871-1.319-5.023-3.667-5.023s-3.7,1.151-3.7,4.9V1.337h-5.686V-25.956L-6.8-29.237v10.4a7.066,7.066,0,0,1,5.063-1.895c5.518,0,7.988,3.786,7.988,9.883V1.337Z" transform="translate(12.486 29.237)" fill="#fff"/>
                </g>
                <g id="Group_17" data-name="Group 17" transform="translate(157.001 19.393)">
                    <path id="Path_92" data-name="Path 92" d="M.6.945H-5.253l-7.779-21.616h6.279L-3.58-10.665c.535,1.646,1.111,3.7,1.36,4.733C-1.974-7-1.4-8.979-.86-10.665L2.314-20.671H8.407Z" transform="translate(13.032 20.671)" fill="#fff"/>
                </g>
                <g id="Group_18" data-name="Group 18" transform="translate(179.288 18.763)">
                    <path id="Path_93" data-name="Path 93" d="M.816.572h-12.93a4.162,4.162,0,0,0,4.447,4.08A4.834,4.834,0,0,0-3.958,3.129L.076,6.507a10.084,10.084,0,0,1-7.744,3.5c-6.215,0-10.173-4.407-10.173-11.244,0-6.628,4.162-11.279,9.638-11.279,5.766,0,9.1,5.105,9.1,11.279A18.011,18.011,0,0,1,.816.572M-8.491-7.4c-2.059,0-3.333,1.686-3.541,3.871H-4.62C-4.783-5.462-5.934-7.4-8.491-7.4" transform="translate(17.84 12.516)" fill="#fff"/>
                </g>
                </g>
            </g>
        </svg>
        <div class="ml-5" style="float:left; width:50%; position:absolute; top:30%">
            <h1 style="color:white; font-size:70px">Create your site plan image</h1>
        </div>
        <a-button @click="imagePage=false; rulePage=true" size="large" type='primary' style="background-color:#9693E7; border-color:#9693E7; position:absolute; bottom:30px; left:30px"><a-icon type='arrow-left' />BACK</a-button>
        <div class="headline" >
            <h3 class="ml-5 mt-5">This will appear as your base site plan image</h3>
            <!-- <div class="">
                <div class="image-box">
                    <ul class="mt-10" style="list-style-type: none; padding: 0;">
                    <li style="padding-bottom: 30px;"><a-icon type="plus" style="font-size: 25px; color:white; background-color:var(--med-gray); padding:15px; border-radius:50px" /></li>
                    <li>Add Image</li>
                    </ul>
                </div>
            </div> -->
            <ImageBox class="ml-5" :img="baseImage" @callback="updateImage"></ImageBox>
            <a-button @click="pushApp" size="large" type='primary' style="background-color:#9693E7; border-color:#9693E7; position:absolute; bottom:30px; right:30px">DONE</a-button>
        </div>
    </div>
    </div>
</template>

<script>
import ImageBoxSelector from 'bh-mod/components/common/ImageBoxSelector'
import ImageBox from '@/components/siteplan/ImageBox'
export default {
    components:{ImageBoxSelector, ImageBox},
    data() {
        return {

            control:{
                elevSide:'Choose an answer below:',
                sameElevLotSpaces:'Choose an answer below:',
                elevPer10:'Choose an answer below:',
                elevStreetPercentage:'Choose an answer below:', //10-50
                sameElevAcross:'Choose an answer below:',
                sameBrickLotSpaces:'Choose an answer below:',
                sameBrickAcross:'Choose an answer below:'
            },
            imagePage:false,
            baseImage:''
        }
    },
    computed: {
        rulePage() {
            return this.$store.state.siteplan.rulePage
        },
        siteplan(){
            return this.$store.state.siteplan.appData
        },
        instance() {
            return this.$store.state.instance
        },
        seenIntro() {
            return this.$store.state.siteplan.seenIntro
        }
    },
    methods:{
        maybeLater() {
            this.imagePage = true
        },
        applyRule() {
            this.$api.post(`/architectural-controls/${this.$store.state.instance.id}/${this.siteplan.id}`, this.control).then( ({data}) => {
                this.$store.commit('APPLY_RULES', data)
                this.imagePage = true
            }).catch(err => {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			})
        },
        updateImage(data) {
            this.baseImage = data
        },
        handleChange(value) {
            console.log(`selected ${value}`);
        },
        pushApp() {
            if (!this.baseImage) return this.$message.error('Please choose a Base Image')
            // let width = document.querySelector('.baseImage').naturalWidth
            // let height = document.querySelector('.baseImage').naturalHeight


            this.$api.post(`/siteplans/${this.instance.id}`, {name:this.instance.name,instance:this.instance.id, image:this.baseImage}).then( ({data}) => {
                console.log('DATAAAAAAA', data)
                this.$store.dispatch('SET_APPDATA',{tags:[],siteplan:data})
            }).catch( err => {
                let error = this.$err(err)
                this.$message.error(error)
            })
            this.$store.commit('CLOSE_RULE')
            this.imagePage = false
            this.$store.commit('SEEN_INTRO')
        },
        showIntro() {
            this.$store.commit('INTRO_PAGE')
        }
    }
}
</script>

<style scoped>
.introPage {
    position:fixed;
    background-color: #9693E7;
    top:0px;
    right:0px;
    bottom:0px;
    left:0px;
    z-index:1000
}
.headline {
    width:50%;
    height:100%;
    background-color: var(--white);
    float:right;
    top: 0px;
}
.image-box {
    width: 350px;
    height: 200px;
    background-color: var(--off-white-dark);
    text-align: center;
    background-repeat:no-repeat;
    background-size:contain;
    background-position: center;
    border: 2px dashed #d9d9d9;
    border-radius: 4px;
    cursor: pointer;
    vertical-align: middle;

}
.image-box:hover{
      border-color: var(--orange);
      transition: border-color .3s ease;
  }
</style>

<style lang="scss">

</style>
