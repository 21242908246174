<template>
<div>
  <a-modal :width="650" :visible="modal.visible" @cancel="modal.visible = ! modal.visible" :centered="true" :footer="null">
    <div class="mt-3">
      <img style="object-fit:contain; width:100%" :src="modal.image" />
    </div>
  </a-modal>
  <div class="image-holder-box" :style="setWidth ?  'width:350px':'width:100%'" @mouseover="showOptions = true" @mouseleave="showOptions = false">
    <div class="dF aC jC" v-if="img == ''" style="height:100%; width:100%" @click="$store.commit('MEDIA_SELECT',{callback:selectedMarkerImage,type:'images'})" >
      <div>
        <a-icon type="plus" style="color:white; font-size:30px; background-color:#ECE9F1; padding:15px; border-radius:50px" />
        <div class="mt-3">Add Image</div>
      </div>
    </div>
    <div v-else class="w-full h-full relative">
      <div v-if="showOptions && type == 1" class="absolute dF aC jC" style="z-index:1000; width: 100%; height: 100%; background-color: rgba(64, 69, 76, 0.5);">
        <a-button-group>
          <!-- <a-button>Image</a-button> -->
          <a-button @click="$store.commit('MEDIA_SELECT',{callback:selectedMarkerImage,type:'images'})"><a-icon type="edit" /></a-button>
          <a-button @click="previewImage(img)"><a-icon type="eye" /></a-button>
          <a-button @click="$emit('callback', '')"><a-icon type="delete" /></a-button>
        </a-button-group>
      </div>
      <img style="width:100%; height:100%; object-fit:cover" :src="img" />
    </div>
  </div>
  <div class="mt-3" :style="setWidth ?  'width:350px':'width:100%'">
    <div v-if="type == 2 && img !== ''" class="dF" style="justify-content: space-between">
      <div @click="$store.commit('MEDIA_SELECT',{callback:selectedMarkerImage,type:'images'})" class="px-3" style="border-radius:4px; border:solid 1px #707070; cursor: pointer">UPLOAD IMAGE</div>
      <div @click="$emit('callback', '')" class="px-3" style="border-radius:4px; border:solid 1px #707070; cursor: pointer">REMOVE IMAGE</div>
    </div>
  </div>
  <!-- <div class="image-holder-box" style="width: 100%; height: 200px; padding: 0;" @mouseover="showOptions = true" @mouseleave="showOptions = false">
    <div v-if="img === ''" style="width:100%; height:100%" @click="$store.commit('MEDIA_SELECT',{callback:selectedMarkerImage,type:'images'})">
      <div v-if="type == 1" class="dF aC jC" style="width:100%; height:100%">
        <div>
          <ul style="list-style-type: none; padding: 0; margin:0">
            <li style=""><a-icon type="plus" style="color:white; font-size:30px; background-color:var(--med-gray); padding:15px; border-radius:50px" /></li>
            <li>Add Image</li>
          </ul>
        </div>
      </div>
      <div v-if="type == 2" class="dF aC jC" style="width:100%; height:100%">
        <div class="px-3" style="border-radius:4px; border:solid 1px #707070"><i class="mr-2 fa fa-plus"/>ADD FILE</div>
      </div>
    </div>
    <div v-else class="relative">
      <div v-if="type == 1" v-show="showOptions" style="width:100%; height:200px; align-items:center" class="image-selector">
        <a-button-group>
          <a-button>Image</a-button>
          <a-button @click="$store.commit('MEDIA_SELECT',{callback:selectedMarkerImage,type:'images'})"><a-icon type="edit" /></a-button>
          <a-button @click="$emit('callback', '')"><a-icon type="delete" /></a-button>
        </a-button-group>
      </div>
      <div v-if="type == 1" v-show="showOptions" style="width: 100%; height: 200px; background-color: rgba(64, 69, 76, 0.5); position: absolute;"></div>
      <div style="background-repeat: no-repeat; background-size: contain; background-position: 50%; width: 100%; height: 200px;" :style="{ 'background-image': 'url(' + img + ')' }" />
    </div>
  </div>
  <div v-if="type == 2 && img !== ''" class="dF mt-3" style="justify-content: space-between">
    <div @click="$store.commit('MEDIA_SELECT',{callback:selectedMarkerImage,type:'images'})" class="px-3" style="border-radius:4px; border:solid 1px #707070">UPLOAD IMAGE</div>
    <div @click="$emit('callback', '')" class="px-3" style="border-radius:4px; border:solid 1px #707070">REMOVE IMAGE</div>
  </div> -->
</div>
</template>

<script>

import ImageBoxSelector from 'bh-mod/components/common/ImageBoxSelector'

export default {
  components:{ImageBoxSelector},
  props: {
    setWidth:{
      default:true,
      type:Boolean
    },
    type:{
      default:1,
      type:Number
    },
    img:{
      default: '',
      type: String
    },
  },
    data() {
        return {
          showOptions: false,
          newImg: '',
          modal:{
            visible:false,
            image:''
          }
        };
        
    },
    watch:{
      newImg(val){

        let self = this
        let ext = val.split('.')[val.split('.').length -1] 

        let img = document.createElement('img');
        img.onload = () => {
            console.log('width => ',img.naturalWidth);
            console.log('height => ',img.naturalHeight);
            let width = img.naturalWidth
            let height = img.naturalHeight
            if (ext !== 'svg' && (!width || !height) ) {
                self.img = ''
                return self.$message.error('Image Error. Not a Valid Image')
            }
            self.$emit('input',val)
            self.$emit('sized',{ext,width,height})
        }
        img.src = val;
          
      }
    },
    computed: {
        
    },
    methods: {
    selectedMarkerImage(url){
      this.newImg = url.url
      this.$emit('callback', this.newImg)
    },
    previewImage(img) {
        this.modal.visible = true
        this.modal.image = img
      }
    },
};
</script>

<style scoped>
.image-holder-box{
  height:250px;
  border-radius:4px; 
  border:dashed 1px #000;
  cursor:pointer;
}
.image-holder-box:hover{
  border-color:var(--orange);
}
</style>
